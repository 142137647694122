//React
import React from 'react'

//Gatsby
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Components
import { formatText } from '../../utils/constants'

const Snippet = (props) => (
  <StaticQuery
    query={graphql`
      query {
        featureAsset: file(
          relativePath: {
            eq: "featureperspective/feature-publisher-canvas.png"
          }
        ) {
          childImageSharp {
            fixed(width: 240, height: 320, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureVoice: file(
          relativePath: { eq: "feature/publisher-voice.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureSocialProfile: file(
          relativePath: { eq: "featureperspective/feature-social-profile.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 320, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureConsistent: file(
          relativePath: { eq: "featureperspective/feature-social-search.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 320, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureDelivery: file(
          relativePath: { eq: "featureperspective/feature-share-gym.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 320, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureEnablement: file(
          relativePath: { eq: "featureperspective/feature-social-feed.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 320, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureSecurity: file(
          relativePath: { eq: "feature/publisher-security.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featureReporting: file(
          relativePath: { eq: "feature/publisher-security.png" }
        ) {
          childImageSharp {
            fixed(width: 240, height: 260, cropFocus: NORTH) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => (
      <div
        class={`feature-body row d-flex flex-row align-items-center justify-content-${
          props.direction === 'reverse' ? 'end' : 'start'
        }`}
      >
        {props.direction && props.direction === 'reverse' ? (
          <div class="feature-body-text col-md-7">
            <ul>
              {props.data.map((item, i) => (
                <li>
                  <strong>{item.title}.&nbsp;</strong>
                  {formatText(item.body)}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <div class="col-md-5 d-flex flex-row align-items-center justify-content-center">
          {props.context === 'create' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureAsset.childImageSharp.fixed}
            />
          ) : null}

          {props.context === 'discover' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureSocialProfile.childImageSharp.fixed}
            />
          ) : null}

          {props.context === 'customize' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureConsistent.childImageSharp.fixed}
            />
          ) : null}

          {props.context === 'equip' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureEnablement.childImageSharp.fixed}
            />
          ) : null}

          {props.context === 'share' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureDelivery.childImageSharp.fixed}
            />
          ) : null}

          {props.context === 'reporting' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureReporting.childImageSharp.fixed}
            />
          ) : null}
          {props.context === 'security' ? (
            <Img
              alt={props.imagealt}
              style={{ minWidth: 240 }}
              fixed={data.featureSecurity.childImageSharp.fixed}
            />
          ) : null}
        </div>
        {!props.direction ? (
          <div class="feature-body-text col-md-7">
            <ul>
              {props.data.map((item, i) => (
                <li>
                  <strong>{item.title}.&nbsp;</strong>
                  {formatText(item.body)}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    )}
  />
)

export default Snippet
