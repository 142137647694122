//React
import React from 'react'

//Packages
import Scrollspy from 'react-scrollspy'

//Icons
import { FaClipboardList, FaPencilRuler, FaMoneyCheckAlt } from 'react-icons/fa'
import { MdPhonelinkRing, MdLock, MdSearch } from 'react-icons/md'
import { IoMdListBox, IoIosRocket, IoMdBody, IoMdImages } from 'react-icons/io'
import {
  AiFillThunderbolt,
  AiOutlineTeam,
  AiOutlineCloudDownload,
  AiFillNotification,
  AiOutlineEdit,
} from 'react-icons/ai'
import { RiPencilRuler2Fill, RiCompassDiscoverLine } from 'react-icons/ri'
import { FiUsers } from 'react-icons/fi'

class Snippet extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      scrolled: '',
    }
  }
  componentDidMount() {
    this.setState({ scrolled: 'onboarding' })
  }
  render() {
    return (
      <div class="feature-menu">
        <Scrollspy
          items={[
            // 'consistency',
            'discover',
            'create',
            'customize',
            'share',
            'equip',
            // 'security',
          ]}
          onUpdate={(item) => {
            this.setState({
              scrolled:
                item && item.getAttribute('id') ? item.getAttribute('id') : '',
            })
          }}
        >
          {/* <li
            class={
              this.state.scrolled && this.state.scrolled === 'onboarding'
                ? 'menu-active bg-purple-4'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#rapid">
              <IoIosRocket
                className="purple-1"
                style={{
                  fontSize: 18,
                  strokeWidth: 1,
                  marginTop: -3,
                }}
              />
              <span class="black-3">Rapid creation</span>
            </a>
          </li> */}
          <li
            class={
              this.state.scrolled && this.state.scrolled === 'discover'
                ? 'menu-active bg-blue-5'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#discover">
              <RiCompassDiscoverLine
                className="dkblue-1"
                style={{
                  fontSize: 16,
                  // strokeWidth: 1,
                  marginTop: -3,
                  marginLeft: 1,
                  marginRight: 1,
                }}
              />
              <span class="black-3">Discover </span>
            </a>
          </li>
          <li
            class={
              this.state.scrolled && this.state.scrolled === 'create'
                ? 'menu-active bg-sky-5'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#create">
              <IoMdImages
                className="sky-1"
                style={{
                  fontSize: 18,
                  strokeWidth: 1,
                  marginTop: -3,
                }}
              />
              <span class="black-3">Create</span>
            </a>
          </li>
          <li
            class={
              this.state.scrolled && this.state.scrolled === 'customize'
                ? 'menu-active bg-dkgreen-5'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#customize">
              <AiOutlineEdit
                className="dkgreen-1"
                style={{
                  fontSize: 16,
                  // strokeWidth: 1,
                  marginTop: -3,
                  marginLeft: 0,
                  marginRight: 1,
                }}
              />
              <span class="black-3">Customize</span>
            </a>
          </li>
          <li
            class={
              this.state.scrolled && this.state.scrolled === 'share'
                ? 'menu-active bg-dkorange-5'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#share">
              <AiFillThunderbolt
                className="dkorange-1"
                style={{
                  fontSize: 18,
                  strokeWidth: 1,
                  marginTop: 0,
                  marginLeft: -2,
                  marginRight: 2,
                }}
              />
              <span class="black-3">Share</span>
            </a>
          </li>
          <li
            class={
              this.state.scrolled && this.state.scrolled === 'equip'
                ? 'menu-active bg-red-5'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#equip">
              <AiFillNotification
                className="red-1"
                style={{
                  fontSize: 17,
                  strokeWidth: 1,
                  marginTop: -3,
                  marginLeft: -2,
                  marginRight: 3,
                }}
              />
              <span class="black-3">Equip</span>
            </a>
          </li>
          {/* <li
            class={
              this.state.scrolled && this.state.scrolled === 'security'
                ? 'menu-active bg-purple-4'
                : ''
            }
          >
            <a class="feature-menu-link scroll" href="#security">
              <MdLock
                className="purple-1"
                style={{
                  fontSize: 18,
                  strokeWidth: 1,
                  marginTop: -3,
                  marginLeft: -2,
                  marginRight: 2,
                }}
              />
              <span class="black-3">Security &amp; privacy</span>
            </a>
          </li> */}
        </Scrollspy>
      </div>
    )
  }
}

export default Snippet
