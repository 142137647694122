//React
import React from 'react'

//Icons
import { FaClipboardList, FaPencilRuler, FaMoneyCheckAlt } from 'react-icons/fa'
import { MdPhonelinkRing, MdLock, MdSearch } from 'react-icons/md'
import { IoIosRocket, IoMdBody, IoMdImages } from 'react-icons/io'
import {
  AiOutlineTeam,
  AiFillThunderbolt,
  AiOutlineCloudDownload,
  AiFillNotification,
  AiOutlineEdit,
} from 'react-icons/ai'
import { RiPencilRuler2Fill, RiCompassDiscoverLine } from 'react-icons/ri'
import { HiOutlineMicrophone } from 'react-icons/hi'
import { FiUsers } from 'react-icons/fi'

const Snippet = (props) => (
  <div
    id={props.context}
    class={`feature-title bg-${props.color}-1 d-flex flex-row align-items-center justify-content-start`}
  >
    <div
      class={`feature-detail-icon-outer-container d-flex flex-column align-items-center justify-content-center bg-${props.color}-4`}
    >
      <div
        class={`feature-detail-icon-container d-flex flex-column align-items-center justify-content-center bg-${props.color}-1`}
      >
        {props.context === 'onboarding' ? (
          <IoIosRocket
            style={{
              color: '#ffffff',
              fontSize: 30,
              opacity: 0.8,
              strokeWidth: 1,
            }}
          />
        ) : null}
        {props.context === 'create' ? (
          <IoMdImages
            style={{
              color: '#ffffff',
              fontSize: 30,
              opacity: 0.8,
              strokeWidth: 1,
            }}
          />
        ) : null}
        {props.context === 'voice' ? (
          <HiOutlineMicrophone
            size={28}
            style={{
              color: '#ffffff',
              fontSize: 28,
              opacity: 0.8,
              strokeWidth: 1,
            }}
          />
        ) : null}
        {props.context === 'discover' ? (
          <RiCompassDiscoverLine
            size={28}
            style={{
              color: '#ffffff',
              fontSize: 28,
              opacity: 0.8,
              // strokeWidth: 1,
            }}
          />
        ) : null}
        {props.context === 'customize' ? (
          <AiOutlineEdit
            size={28}
            style={{
              color: '#ffffff',
              fontSize: 28,
              opacity: 0.8,
              // strokeWidth: 1,
            }}
          />
        ) : null}
        {props.context === 'equip' ? (
          <AiFillNotification
            style={{
              color: '#ffffff',
              fontSize: 32,
              opacity: 0.8,
              strokeWidth: 1,
            }}
          />
        ) : null}
        {props.context === 'share' ? (
          <AiFillThunderbolt
            style={{
              color: '#ffffff',
              fontSize: 30,
              opacity: 0.8,
              strokeWidth: 1,
            }}
          />
        ) : null}
        {props.context === 'security' ? (
          <MdLock
            size={26}
            style={{
              color: '#ffffff',
              fontSize: 26,
              opacity: 0.8,
              strokeWidth: 1,
            }}
          />
        ) : null}
      </div>
    </div>
    <span>{props.title}</span>
  </div>
)

export default Snippet
