//React
import React from 'react'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import FeatureMenu from '../../components/marketing/featureMenu'
import FeatureTitle from '../../components/marketing/featureTitle'
import FeatureBody from '../../components/marketing/featureBody'

const Features = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Features - Visual Engagement Platform"
      meta={[
        {
          name: 'description',
          content:
            'See features of the Storylava visual engagement platform with tools to rapidly produce social graphics and videos from templates',
        },
      ]}
    />
    <div class="gradient-callout-algae">
      <div class="container container-page container-about-sub">
        <div class="row">
          <div class="col-lg-8 d-flex flex-column justify-content-center text-center text-lg-left">
            <h1 class="display-2 font-weight-medium white-1">Features</h1>
            <p class="display-4 white-4">
              {/* Discover, follow, and be inspired by designers and templates you
              can customize &mdash; and drive business impact */}
              Storylava helps you create social media content by discovering
              amazing designers &amp; templates &mdash; and remixing their
              content.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container container-page container-features">
      <div class="row d-flex">
        <div class="col-feature-menu d-none d-md-block col-md-4">
          <FeatureMenu />
        </div>
        <div class="col-md-8">
          {/* BEGIN SECTION */}
          <FeatureTitle
            context="discover"
            title="Discover social marketing ideas"
            color="blue"
          />
          <FeatureBody
            context="discover"
            imagealt=""
            direction="reverse"
            data={[
              {
                title: 'Discover fresh designs',
                body:
                  'Find designers with content relevant for your industry - and follow them for inspiration',
              },
              {
                title: 'Remix design content',
                body:
                  'Quickly load, remix, and customize templates from your favorite designers, and use to engage your customers',
              },
            ]}
          />

          <FeatureTitle context="create" title="Create content" color="sky" />
          <FeatureBody
            context="create"
            imagealt=""
            data={[
              {
                title: 'Create anywhere',
                body:
                  'Create compelling content on mobile, tablet or desktop - no app install or download required',
              },
              {
                title: 'Point-and-click',
                body:
                  'Rapidly create creative assets for selling via point-and-click, easy for even inexperienced users',
              },

              {
                title: 'Social images',
                body:
                  'Quickly produce social images using pre-built templates that are easily customized',
              },
              {
                title: 'Dark & light modes',
                body:
                  'Create in light mode with full vividness, or dark mode for an easier-on-the-eyes experience',
              },
              // {
              //   title: 'Short-form video',
              //   body:
              //     'Quickly create short-form brand videos to promote your products on Instagram Stories, TikTok, and more. COMING SOON',
              // },
              // {
              //   title: 'Mobile & cross-platform',
              //   body:
              //     'Engage with users on their phones across platforms: iOS, Android, Windows, and popular browsers',
              // },
            ]}
          />
          <FeatureTitle
            context="customize"
            title="Customize templates"
            color="dkgreen"
          />
          <FeatureBody
            context="customize"
            imagealt=""
            direction="reverse"
            data={[
              {
                title: 'Brand templates',
                body:
                  'Use brand templates to ensure a consistent look-and-feel across locations and affiliates',
              },
              {
                title: 'Fonts and colors',
                body:
                  'Let users customize templates quickly with context-aware menus for attributes like fonts, colors and more',
              },
              {
                title: 'Image library',
                body:
                  'Equip your team with your brand image assets that they can insert easily into sales & marketing assets',
              },
            ]}
          />

          <FeatureTitle
            context="share"
            title="Share on social"
            color="dkorange"
          />

          <FeatureBody
            context="share"
            imagealt=""
            data={[
              {
                title: 'Rapid sharing links',
                body:
                  'Drive social reach with rapid sharing links to Facebook, Twitter, Instagram, and more',
              },
              {
                title: 'Downloadable files',
                body:
                  'Deliver a downloadable file for sharing via TikTok, Snapchat, email, and other platforms',
              },
              {
                title: 'Save to user library',
                body:
                  "Save template to a user's personal library for access later",
              },
            ]}
          />
          <FeatureTitle context="equip" title="Equip your teams" color="red" />
          <FeatureBody
            context="equip"
            imagealt=""
            direction="reverse"
            data={[
              {
                title: 'Up-to-date assets',
                body:
                  'Push updated templates to users quickly so they have the latest campaign assets',
              },
              {
                title: 'Notifications & alerts',
                body:
                  "Notify users of the latest content via SMS or email so they're having the most relevant conversations with prospects",
              },
            ]}
          />

          {/* <FeatureTitle
            context="security"
            title="Security & privacy"
            color="purple"
          />

          <FeatureBody
            context="reporting"
            imagealt="Social sharing capabilities"
            data={[
              {
                title: 'Security',
                body:
                  'Maintain security with encryption to protect data both in transit and at rest',
              },
              {
                title: 'Privacy',
                body:
                  'Protect data access with controls permitting access only to authorized users of your company',
              },
            ]}
          /> */}

          {/* <FeatureTitle
            context="security"
            title="Security & privacy"
            color="orange"
          />

          <FeatureBody
            context="security"
            imagealt="Analytics reporting"
            data={[
              {
                title: 'Security',
                body:
                  'Maintain security with encryption to protect data both in transit and at rest',
              },
              {
                title: 'Privacy',
                body:
                  'Protect user privacy with robust controls permitting access only to authorized admin users of your company',
              },
            ]}
          /> */}

          {/* <FeatureTitle context="analytics" title="Analytics" color="purple" />
          <div class="feature-body d-flex flex-row align-items-center justify-content-start">
            <Img
              alt="Content moderation"
              style={{ minWidth: 240 }}
              fixed={props.data.featureModeration.childImageSharp.fixed}
            />
            <div class="feature-body">
              <ul>
                <li>Feature 1</li>
                <li>Feature 2</li>
                <li>Feature 3</li>
              </ul>
            </div>
          </div> */}
          {/* END SECTIONS */}
        </div>
      </div>
    </div>
  </Layout>
)

export default Features
